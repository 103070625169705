<template>
  <div v-html='htmlContent' :style='sizeBasedStyles'></div>
</template>

<script>

export default {
  name: 'HtmlPage',
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    mobileStyles: {
      type: String,
      default: '',
    },
    desktopStyles: {
      type: String,
      default: '',
    },
  },
  computed: {
    sizeBasedStyles () {
      let mediaQuery = window.matchMedia('(min-width: 1024px)') // this is the lg taliwind size
      return (mediaQuery.matches) ? this.desktopStyles : this.mobileStyles
    },
  },
}
</script>
